import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngRealStateDevelopment = loadable(() => import("../components/RealStateDevelopment/english.js"));
const ArabicRealStateDevelopment = loadable(() => import("../components/RealStateDevelopment/arabic.js"));
const ChineseRealStateDevelopment = loadable(() => import("../components/RealStateDevelopment/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-20.webp",
            "name": "Top Real Estate Tokenization Company",
            "description": "Softtik Technologies is a leading real estate tokenization company offering exceptional opportunities in the property space through a futuristic vision.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "108"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What does tokenization of real estate mean?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Tokenizing real estate means converting the total value into small chunks of property for crowdfunding. Then each part is encrypted with details representing ownership of that specific portion of the property.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Which company can tokenize real estate?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Many companies exist, but <b>Softtik Technologies</b> is the leading name in <b>real estate tokenization</b>. With a plethora of experienced developers and professionals, the company is helping many people.</p>"
                }
            }, {
                "@type": "Question",
                "name": "What assests can be tokenized?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Tokenization is a broad terminology in the world of blockchain and crypto. Anything unique on this planet can be tokenized.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How can I tokenize my real estate?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Tokenizing real estate is a great idea to access cash and other advantages quickly. <b>Softtik Technologies</b> can help you tokenize your real estate assets quickly and efficiently.</p>"
                }
            }
            ]
        }
    ]
}

export class RealStateDevelopment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/real-estate-tokenization-services/"
                        title="Real Estate Tokenization Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-20.webp"
                        description="Softtik Technologies is a leading real estate tokenization company offering exceptional opportunities in the property space through a futuristic vision."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicRealStateDevelopment />
                                    : lang == "China"
                                        ? <ChineseRealStateDevelopment />
                                        : <EngRealStateDevelopment />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default RealStateDevelopment;